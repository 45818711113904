import axios from "axios";
import { Key, useState, useEffect } from "react";
import ReactPixel from 'react-facebook-pixel';
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import colours from "../styles/appColours";
import utils from "../utils/utils";
import BasicModal from "../components/Modal";
import Subinfo from "../components/Subinfo";
import { useQuery } from "react-query";
import api from "../services/api";

const placeorderNavList = [
  { route: "/about", name: "About Us" },
  { route: "/home", name: "Place Order" },
  { route: "/blog", name: "Blog" },
  { route: "/track", name: "Track Order" },
  { route: "/pricing", name: "Pricing" },
];

export const Pricing = () => {
    const navigate = useNavigate();
    const apiRequest =  api()
    const { data: subscriptionPlans, isLoading } = useQuery("SUBSCRIPTION_PLANS",  () => apiRequest.getSubPlans());
    const [open, setOpen]=useState(false)

      useEffect(() => {
        ReactPixel.pageView();
      }, []);

  return (
    <>
    <Helmet>
          <title>Subscriptions - eFTD Africa</title>
          <meta
            name="description"
            content="Official website of eFTD logistics, An Eco-friendly logistics company in Nigeria,Fast and reliable delivery service in Lagos, using Zero emission eletric two-wheeler. eFTD also has an EV Charging station in Nigeria."
          />
        <meta
          name="keywords"
          content="Sustainability, What is the meaning of ICE?, What type of battery does an EV use?, EV battery capacity, Higher purchase dispatch bike,Greenhouse gases,Life cycle emission"
        />
        </Helmet>
      <Header navlist={placeorderNavList} />
      <Wrapper>
      <NoticeWrapper>
        <h1>Please Note</h1>
        <p>By Subscribing to any plan, you have accepted our <span onClick={()=>setOpen(true)}>Terms and Conditions.</span></p>
      </NoticeWrapper>
        <CardsWrapper>
          {subscriptionPlans?.map((subs: any, index: Key | null | undefined) => (
            <Cards key={index}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <SubType>{subs?.name}</SubType>
                <SubAmount>
                  {`N${Number(subs?.amount / 100).toLocaleString()}`}
                </SubAmount>
              </div>
              <SubInfo>
                <SubListItem>
                  N{(subs?.baseFee / 100).toLocaleString()} per delivery within
                  Lagos.
                </SubListItem>
                <SubListItem>
                  {subs?.allowedDeliveries} pick ups and deliveries
                </SubListItem>
                <SubListItem>{subs?.description || "N/A"}</SubListItem>
                {subs?.benefits?.length > 0 &&
                  utils.formatDescription(subs?.benefits)
                    .map((benefit) => (
                      <SubListItem key={benefit}>{benefit}</SubListItem>
                    ))}
                <SubListItem>{subs?.validity} day validity period</SubListItem>
              </SubInfo>
              <SubButton onClick={() => {navigate('/subscription')}}>Subscribe Now</SubButton>
            </Cards>
          ))}
        </CardsWrapper>
      </Wrapper>

      <BasicModal close={()=>setOpen(false) } open={open} content={<Subinfo toggle={()=>setOpen(false)}/>} width={'90vw'} height={'90vh'}/>
      <Footer />
    </>
  );
};

const Wrapper = styled.main`
  min-height: 100vh;
  height: 100%;
  overflow-x:hidden;
  background-color: ${colours.background};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 425px) {
    margin-bottom: 40px;
  }
  @media (max-width: 375px) {
  }
`;

const NoticeWrapper = styled.div`
  background-color: #FE7F2020;
  color: #000;
  margin-top: 120px;
  text-align: left;
  width: 68vw;
  padding: 10px 10px;
  border: 1px solid #FE7F20;
  border-radius: 10px;

  h1 {
    font-size: 16px;
    text-align: left;
  }
  p{
    font-size: 14px;

    span {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  @media (max-width: 600px) {
    width: 90vw;
  }

`


const CardsWrapper = styled.section`
  background-color: ${colours.background};
  /* height: 85vh; */
  width: 80vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 5%;
  column-gap: 5%;
  border-radius: 32px;
  margin: 0px;
  margin-top: 50px;
  padding: 30px;
  /* border: 1px solid green; */
  box-sizing: content-box;
  @media (max-width: 425px) {
    width: 90vw;
    gap: 20px;
  }
  @media (max-width: 375px) {
  }
`;

const Cards = styled.section`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 40%;
  min-width: 350px;
  margin-bottom: 20px;
  /* height: 100%; */
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0px 38.5522px 66.0896px ${colours.background};
  border: none;
  @media (max-width: 425px) {
    width: 90%;
    :last-child {
      margin-bottom: 40px;
    }
    :first-child {
      margin-top: 20px;
    }
  }
  @media (max-width: 375px) {
  }
`;

const SubType = styled.p`
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;

  /* Gray 1 */

  color: #333333;
`;

const SubAmount = styled.p`
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;

  /* Gray 1 */

  color: #333333;
`;

const SubInfo = styled.ul`
  color: #8c8888;
  font-size: 15px;
  font-style: normal;
  margin: 0px 0px 15px 0px;
  padding: 0px;
  line-height: 35px;
  text-align: left;
  list-style: none;
  margin-top: 20px;
`;

const SubListItem = styled.li`
  margin: 0;
`;

const SubButton = styled.p`
  cursor: pointer;
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #f66d00;
  text-align: left;
`;
